import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MDBox from "../../components/MDBox";
import MDButton from "../../components/MDButton";
import MDTypography from "../../components/MDTypography";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import Footer from "../../examples/Footer";
import BookingCard from "../../examples/Cards/BookingCard";
import thumbnail from "../../assets/images/thumbnail.jpg";
import Card from "@mui/material/Card";
import { useLanguageState } from "../../context/LanguageContext";
import loadTranslations from "../../utils/loadTranslations";
import MDInput from "../../components/MDInput";
import { TextField } from "@mui/material";

const Instagram = () => {
  const { language } = useLanguageState();
  const [translations, setTranslations] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchTranslations = async () => {
      const loadedTranslations = await loadTranslations(language);
      setTranslations(loadedTranslations);
    };

    fetchTranslations();
  }, [language]);

  const handleOpenFlowBuilder = () => {
    const group_id = localStorage.getItem("name");

    if (!group_id) {
      toast.error("Name is missing from localStorage");
      return;
    }

    window.open(
      `https://insta.crmstation.net/home/login_newview?token=${group_id}`,
      "_blank"
    );
  };
  const [inputValue2, setInputValue2] = useState("");

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <MDBox mt={6}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={2}>
              <MDBox mt={3}>
                <BookingCard
                  image={thumbnail}
                  title="Tutorial"
                  description='The place is close to Barceloneta Beach and bus stop just 2 min by walk and near to "Naviglio" where you can enjoy the main night life in Barcelona.'
                  price="$899/night"
                  location="Barcelona, Spain"
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={2}>
              <MDBox mt={3}>
                <BookingCard
                  image={thumbnail}
                  title="Tutorial"
                  description='The place is close to Metro Station and bus stop just 2 min by walk and near to "Naviglio" where you can enjoy the night life in London, UK.'
                  price="$1,119/night"
                  location="London, UK"
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={2}>
              <MDBox mt={3}>
                <BookingCard
                  image={thumbnail}
                  title="Tutorial"
                  description='The place is close to Metro Station and bus stop just 2 min by walk and near to "Naviglio" where you can enjoy the main night life in Milan.'
                  price="$459/night"
                  location="Milan, Italy"
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mt={4}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={6}>
              <MDBox mb={1.5}>
                <Card>
                  <MDBox p={3} display="flex" flexDirection="column">
                    {/* Title: Instagram Bot */}
                    <MDTypography variant="h4" color="textSecondary" mb={3}>
                      Instagram Bot
                    </MDTypography>

                    {/* Input Field 1 */}
                     <TextField
                      type="text"
                      fullWidth
                      label={translations.signin?.user || "Usuário"}
                      value={inputValue2}
                      onChange={(e) => setInputValue2(e.target.value)}
                      mb={2}
                      sx={{ mt: 2 }}
                    />

                    {/* Input Field 2 */}
                     <TextField
                      type="text"
                      fullWidth
                      label={translations.signin?.password || "Senha"}
                      value={inputValue2}
                      onChange={(e) => setInputValue2(e.target.value)}
                      mb={4}
                      sx={{ mt: 2 }}
                    />

                    {/* Smaller Button */}
                <MDButton
                  variant="gradient"
                  color="dark"
                  onClick={handleOpenFlowBuilder}
                  sx={{ mt: 2, width: "200px" }} // Smaller width
                >
                  Open Instagram Bot
                </MDButton>
                  </MDBox>
                </Card>
              </MDBox>
            </Grid>
               {/* Right Part */}
               <Grid item xs={12} md={6} lg={6} display="flex" justifyContent="center" alignItems="center">
                  {/* Add the image */}
                  <MDBox component="img" src="https://321dbase.com/0/crmstation/insta.jpg" alt="Multi Chanel Image" width="100%" maxWidth="400px" />
                </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
      {/* <ToastContainer /> */}
    </DashboardLayout>
  );
};

export default Instagram;
